<template>
  <CommonPage title="Objednávky">
    <form @submit.prevent="sendEmail">
      <div class="form-group">
        <label for="jmeno">Jméno</label>
        <input
          type="text"
          class="form-control"
          id="jmeno"
          v-model.trim="state.jmeno"
          required
        />
      </div>
      <div class="form-group">
        <label for="prijmeni">Příjmení</label>
        <input
          type="text"
          class="form-control"
          id="prijmeni"
          v-model.trim="state.prijmeni"
          required
        />
      </div>
      <div class="form-group">
        <label for="prijmeni">Datum narození</label>
        <input
          type="date"
          class="form-control"
          id="narozeni"
          v-model.trim="state.narozeni"
          required
        />
      </div>
      <div class="form-group">
        <label for="ulice">Ulice</label>
        <input
          type="text"
          class="form-control"
          id="ulice"
          v-model.trim="state.ulice"
          required
        />
      </div>
      <div class="form-group">
        <label for="mesto">Město</label>
        <input
          type="text"
          class="form-control"
          id="mesto"
          v-model.trim="state.mesto"
          required
        />
      </div>
      <div class="form-group">
        <label for="psc">PSČ</label>
        <input
          type="text"
          class="form-control"
          id="psc"
          v-model.trim="state.psc"
          required
        />
      </div>
      <div class="form-group">
        <label for="telefon">Telefon</label>
        <input
          type="tel"
          class="form-control"
          id="telefon"
          v-model.trim="state.telefon"
          required
        />
      </div>
      <div class="form-group">
        <label for="email">E-mail</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model.trim="state.email"
          required
        />
      </div>
      <div class="form-group">
        <label for="poznamka">Poznámka (max. 500 znaků)</label>
        <textarea
          class="form-control"
          placeholder="Zde můžete dopsat pro kolik osob. Jejich jméno, věk a adresu."
          id="poznamka"
          v-model="state.poznamka"
          maxlength="500"
          rows="3"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="jak-se-o-nas-dozvedeli">Jak jste se o nás dozvěděli?</label>
        <select
          class="form-control"
          id="jak-se-o-nas-dozvedeli"
          v-model="state.info"
          required
        >
          <option value="inzerát">Inzerát</option>
          <option value="sociální sítě">Sociální sítě</option>
          <option value="od kamaráda">Od kamaráda</option>
          <option value="jiné">Jiné</option>
        </select>
      </div>
      <div class="form-group">
        <label for="co-chcete-objednat">Co chcete objednat?</label>
        <select
          class="form-control"
          id="co-chcete-objednat"
          v-model="state.objednat"
          required
        >
          <option
            v-for="(item, index) in options"
            :key="'order_' + index"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
      </div>
      <div class="buttons">
        <button type="submit" class="action-button">Odeslat</button>
      </div>
    </form>
  </CommonPage>
</template>

<script setup lang="ts">
import CommonPage from "@/components/CommonPage.vue";
import { reactive } from "vue";
import emailjs from "emailjs-com";
import { toast, type ToastOptions } from "vue3-toastify";

const emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const options = ["Bystřička", "Dunajec", "Kurzy", "Řecko", "Roháče"];

const state = reactive({
  jmeno: "",
  prijmeni: "",
  ulice: "",
  narozeni: new Date(1980, 1, 1),
  mesto: "",
  psc: "",
  telefon: "",
  email: "",
  poznamka: "",
  info: "",
  objednat: "",
});

const sendEmail = async () => {
  // Validace formuláře
  if (!state.jmeno) {
    alert("Zadejte jméno.");
    return;
  }

  if (!state.prijmeni) {
    alert("Zadejte příjmení.");
    return;
  }

  if (!state.ulice) {
    alert("Zadejte ulici.");
    return;
  }

  if (!state.mesto) {
    alert("Zadejte město.");
    return;
  }

  if (!state.psc) {
    alert("Zadejte PSČ.");
    return;
  }
  if (!state.narozeni) {
      alert("Zadejte datum narození.");
      return;
  }
  if (!state.telefon) {
    alert("Zadejte telefonní číslo.");
    return;
  }

  if (!state.email) {
    alert("Zadejte e-mailovou adresu.");
    return;
  } else if (!state.email.match(emailRegex)) {
    alert("E-mailová adresa není platná.");
    return;
  }

  const stateString = Object.entries(state)
    .map(([key, value]) => `${key}: '${value}'`)
    .join(", ");
  toast.info("Objednávka se odesílá");
  try {
    emailjs.init("iYzmrNBAjQVKCYZOj");
    emailjs.send("service_lkxysdh", "template_eit7a5r", {
      from_name: `${state.jmeno} ${state.prijmeni}`,
      jmeno: state.jmeno,
      prijmeni: state.prijmeni,
      narozeni: state.narozeni,
      ulice: state.ulice,
      mesto: state.mesto,
      psc: state.psc,
      telefon: state.telefon,
      email: state.email,
      poznamka: state.poznamka,
      info: state.info,
      objednat: state.objednat,
      event: state.objednat,
      message: stateString,
      reply_to: state.email,
      from_email: state.email,
    });
    toast.success("Objednávka byla úspěšně odeslána");
  } catch (e) {
    console.log(e);
    toast.error("Nastala nečekaná chyba, zkuste to prosím později.");
  }
};
</script>

<style scoped>
/* Action Button */
.action-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.action-button:hover {
  background-color: #45a049;
}

.action-button:active {
  background-color: #3e8e41;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="tel"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
  border-color: #4caf50;
  outline: none;
}

select {
  appearance: none; /* Skryje výchozí šipku u selectu */
}
.form-group {
  margin-bottom: 20px;
}

input[type="date"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input[type="date"]:focus {
  border-color: #4caf50;
  outline: none;
}

/* Option v selectu */
select option {
  padding: 10px;
}

/* Přidá odsazení a trochu prostoru kolem option v selectu */
select {
  padding: 5px;
  margin: 5px 0;
}

/* Tlačítko na výběr souboru */
.input-file {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.input-file input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* Text vedle tlačítka na výběr souboru */
.input-file-label {
  margin-left: 10px;
}
</style>
