<template>
  <div class="article">
    <h1 class="article-title">{{ title }}</h1>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const props = defineProps(["title"]);
</script>

<style>
.article {
  background-color: var(--doplnek-barva-2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin-bottom: 20px;
}

.article-title {
  font-size: 1.5em;
  font-weight: bold;
}

.article-content {
  font-size: 1.2em;
}
</style>
