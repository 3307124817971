<template>
  <div class="nav-bar">
    <ul>
      <li v-for="item in items" :key="item.name">
        <a :href="item.href">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";

const items = [
  { name: "Úvod", href: "/" },
  { name: "Objednávky", href: "/objednavky" },
  { name: "Kontaktni informace", href: "/kontakt" },
];

const state = reactive({
  show: false,
});

</script>
<style scoped>
.nav-bar {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  z-index: 1;
}

.nav-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-bar li {
  display: inline-block;
  margin-right: 20px;
}

.nav-bar a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 5px 10px;
}

.nav-bar button {
  display: none;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nav-bar button:hover,
.nav-bar button.active {
  background-color: #0056b3;
}


/* Rozbalení položek navigace po kliknutí na tlačítko */

.nav-bar button:hover {
  background-color: #0056b3;
}

.nav-bar button.active {
  background-color: #0056b3;
}
</style>
